@import url('https://fonts.googleapis.com/css?family=Roboto:300,700');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

html body {
  height: 100%;
}

.cwa-container {
  display: flex;
  flex-direction: column;
}

.cwa-top {
  height: 854px;
  background-image: url('./assets/background_1.png');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-x: center;
  background-position-y: center;
  color: white;

  &--logo {
    width: 200px;
    height: 80px;
    padding: 37px 77px;
  }

  &--big {
    font-size: 3.75rem;
    max-width: 849px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: -0.36px;
    color: #fff;
  }

  &--sub {
    margin-top: 17px;
    font-size: 1.75rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    letter-spacing: normal;
    max-width: 546px;
  }
}

.cwa-about {
  padding: 160px 0 180px;

  &--title {
    font-size: 3rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
  }

  &--content {
    margin-top: 41px;
    max-width: 989px;
    font-size: 1.5rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #434343;
  }
}

.benefits-container {
  display: flex;
  width: 100%;
  background-image: url('./assets/background_2.png');
  background-size: 100% 864px;
  background-position-x: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  height: 864px;
  position: relative;
}

.testimonials-container {
  display: flex;
  width: 100%;
  background-image: url('./assets/background_3.png');
  background-position-x: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  padding: 0 40px;
  height: 864px;
  background-size: 100% 864px;
}

.content-container {
  max-width: 1280px;
  margin: 0 auto !important;
  float: none !important;
  padding: 100px 0 20px;
  position: relative;
  box-sizing: border-box;
}

.programas-title {
  font-size: 3rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #ffffff;
  margin-top: 160px;
}

.programas-triangles {
  position: absolute;
  left: 0;
  margin-top: -49px;
  width: 171px;
}

.programas-triangles-2 {
  position: absolute;
  right: -4px;
  width: 97px;
  bottom: -112px;
}

.programas-content-container {
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
}

.programas-item {
  display: flex;
  flex-direction: column;

  &--title {
    font-size: 1.5rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin-top: 50px;
    width: 293px;
  }

  &--line {
    max-width: 293px;
    height: 1px;
    background-color: #b2e2a9;
    margin-top: 10px;
  }

  &--content {
    font-size: 1.125rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin-top: 17px;
    max-width: 275px;
  }
}

.programas-btn-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 69px;

  &--no-bold {
    font-weight: normal !important;
  }
}

.programas-btn {
  width: 453px;
  height: 48px;
  border-radius: 20px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #17a391;
  text-decoration: none;
}

.our-programs {
  min-height: 915px;
  background-color: #225eda;
  color: #ffffff;
  box-sizing: border-box;
  margin-top: 48px;
}

.cwa-programs__container {
  margin-bottom: 180px !important;
}

.cwa-programs {
  &--title {
    margin-top: 60px;
    font-size: 3rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  &--sub {
    margin-top: 61px;
  }
}

.our-programs-small-title {
  font-size: 1.125rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff;
}

.our-programs-title {
  width: 40px;
  font-size: 3rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #ffffff;
  margin-top: 20px;
}

.our-programs-description-container {

  margin-top: 29px;

  &--bold-text {
    font-size: 1.125rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #ffffff;
  }

  &--text {
    margin-top: 4px;
    font-size: 0.9375rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #ffffff;
  }
}

.our-programs-content-container {
  margin-top: 80px;
  display: flex;
  justify-content: center;
}

.our-programs-item-2 {
  padding: 76px 0 !important;
}

.our-programs-item {
  width: 278.4px;
  height: 480px;
  padding: 110px 0;
  border-radius: 6px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  background-color: #f6f4f4;
  box-sizing: border-box;
  position: relative;

  &--top {
    top: 0;
    position: absolute;
    width: 74px;
    height: 31px;
    border-radius: 0 0 8px 8px;
    margin-left: 101px;
    margin-top: 0;
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.83;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #141414;
  }

  &--title, &--title-2 {
    font-size: 1.25rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.16;
    letter-spacing: normal;
    text-align: center;
    color: #060c21;
  }

  &--title-2 {

  }

  &--free {
    font-size: 1.125rem;
    margin-top: 17px;
    text-align: center;
  }

  &--free-2 {
    font-size: 1.125rem;
    margin-top: 38px;
    text-align: center;
  }

  &--content-0, &--content {
    font-size: 1.125rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-top: 18px;
    text-align: center;
    height: 63px;
  }

  &--content-0 {
    margin-top: 2px;
  }

  &--content-1 {
    font-size: 0.8125rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.63;
    letter-spacing: normal;
    text-align: center;
    color: #060c21;
    margin-top: 8px;
  }

  &--content-2 {
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-top: 44px;
    text-align: center;
  }

  &--content-3 {
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-top: 10px;
    text-align: center;
  }

  &--content-4 {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 10px;
    font-size: 1.125rem;
  }

  &--bold {
    font-size: 0.875rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin-top: 4px;
    text-align: center;
  }

  &--button {
    width: 180px;
    height: 48px;
    border-radius: 24px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.25rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #17a391;
    text-decoration: none;
  }

  &--button-2 {
    width: 146px;
    height: 48px;
    border-radius: 24px;
    background-color: #17a391;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.25rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    text-decoration: none;
  }

  &--button-3 {
    width: 240px;
    height: 48px;
    border-radius: 24px;
    background-color: #17a391;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.25rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    text-decoration: none;
    margin-top: 90px;
  }

  &--line {
    height: 10px;
    margin-top: 40px;
  }

  &--line-1 {
    height: 10px;
    margin-top: 48px;
  }
}

.our-programs-item:not(:first-child) {
  margin-left: 7rem;
}

.our-programs-item:nth-child(3) .our-programs-item--title {
  color: #222a41;
}

.our-programs-item:nth-child(3) .our-programs-item--content {
  color: #434049;
}

.our-programs-item:nth-child(3) .our-programs-item--bold {
  color: #434049;
}

.our-programs-btn-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 42px;
}

.our-programs-btn-container-2 {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 16px;
}

.cwa-roadmap__container {
  background-color: #f6f6f9;
}

.testimonials-title {
  font-size: 3rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #ffffff;
  margin-top: 160px;
}

.testimonials-item {
  max-width: 361px;
  height: 400px;
  margin-top: 35px;
  border-radius: 18px;
  box-shadow: 0 10px 20px 0 rgba(69, 158, 255, 0.1), 0 20px 40px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;

  &--content {
    padding: 39px 33px 20px 41px;
    box-sizing: border-box;
  }

  &--testimonio-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &--testimonio {
    font-size: 1rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #6d7783;
    height: 225px;
  }

  &--autor-container {
    margin-top: 20px;
  }

  &--autor {
    font-size: 0.9375rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #000000;
  }
}

.iq4-footer {
  font-family: "Roboto Mono", sans-serif;
  background-color: #1f2532;
  color: #fff;
  height: 83px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.375rem;
}

.slideshow {
  overflow: hidden;
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 500ms;

  img {
    width: 100%;
    top: 0;
  }
}

.slide {
  display: inline-block;
  // height: 400px;
  width: 100%;
  border-radius: 40px;
}

/* Buttons */

.slideshowDots {
  display: flex;
  justify-content: flex-end;
  margin-right: 83px;
  margin-bottom: 40px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.slideshowDots_mobile {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  cursor: pointer;
}

.slideshowDot {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
  margin: 15px 7px 0px;
  background-color: #777777
}

.slideshowDot.active {
  background-color: #0090ff
}

/* MOBILE VERSION */
@media screen and (max-width: 795px) {
  .cwa-top {
    padding: 0 15px;
    box-sizing: border-box;
    background-size: auto;
    height: auto;

    &--big {
      margin-top: 40px;
      font-size: 3.125rem;
    }

    &--sub {
      margin-bottom: 80px;
    }
}

.cwa-about {
padding: 40px 15px 0;
box-sizing: border-box;

&--content {
  font-size: 1.375rem;
  padding: 0 15px;
  box-sizing: border-box;
  margin-bottom: 80px;
}
}

.programas-item {
justify-content: center;
align-items: center;
text-align: center;
}

.programas-title {
margin-top: 80px;
}

.benefits-container {
text-align: center;
background-image: url('./assets/background_2_mobile.png');
background-size: cover;
height: auto;
}

.programas-btn {
height: 55px;
border-radius: 28px;
width: 367px;
margin-bottom: 20px;
}

.programas-triangles, .programas-triangles-2 {
display: none;
}

.cwa-programs {
  &--title {
    margin-top: 40px;
    text-align: center;
  }

  &--sub {
    display: none;
  }
}

.programas-content-container {
flex-direction: column;
margin-top: 0;
align-items: center;
  margin-bottom: 80px;
}

.our-programs-content-container {
flex-direction: column;
align-items: center;
}

.our-programs-item:not(:first-child) {
margin-left: 0;
margin-top: 30px;
}

.testimonials-container {
display: flex;
width: 100%;
background-size: auto;
background-position-x: center;
box-sizing: content-box;
justify-content: center;
align-items: center;
height: auto;
background-repeat: repeat;
padding: 0;
}

.testimonials-title {
text-align: center;
margin-top: 80px;
}
}

@media screen and (min-width: 796px) and (max-width: 896px) {
  .cwa-top {
    background-size: auto;
    height: 800px;
  }
}

@media screen and (min-width: 897px) and (max-width: 1280px) {
  .cwa-top {
    background-position-y: top;
  }
}

@media screen and (max-width: 1000px) {
.testimonials-container {
background-repeat: repeat;
height: auto;
}

.testimonials-item {
height: auto;

&--testimonio {
  height: auto;
}
}

.testimonials-item:not(:last-of-type) {
margin-right: 4px;
}
}